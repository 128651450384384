.box-style-profile {
    background-color: #e7ebf0;
    margin: 0px;
    padding: clamp(1px, 1%, 50px);
}
 
.grid-style-profile {
border-style: solid;
background-color: white;
border-color: #e7ebf0;  
border-radius: 16px;
border-width: 8px;
}

.user-grid {
    display: grid;
    place-items: center;
    height: 70vh;
    overflow: auto;
}

.user-avatar {
    display: grid;
    place-items: center;
    overflow: auto;
}