.loading-dots-popover {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-dot-pop {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin: 0 3px;
    background-color: #004CFF;
    /* background-color: #000; */
    animation: pulse 0.72s infinite;
    opacity: 0.2;
  }
  
  .loading-dot-pop:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .loading-dot-pop:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .loading-dot-pop:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes pulse {
    0%, 15% {
      opacity: 1;
    }
    25%, 100% {
      opacity: 0.2;
    }
  }
  

  .blinkingCursor {
    display: inline-block;
    animation: blinkingAnimation 0.7s infinite;
    width: 10px;
    height: 18px;
    background-color: #004CFF;
  }
  
  @keyframes blinkingAnimation {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  
  