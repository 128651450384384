.box-style-submit-tokens {
    background-color: #e7ebf0;
    margin: 0px;
}

.grid-style-submit {
border-style: solid;
background-color: #e7ebf0;
border-color: #e7ebf0;  
border-radius: 16px;
border-width: 8px;
}
.alert-message-token {
    justify-content: center;
}

.submit-tokens {
    display: grid;
    place-items: center;
    justify-content: center;
}

.error-message {
    color: rgba(255, 0, 0, 0.6);
    margin-right: 20px;
    margin-bottom: 5px;
  }
  
.error-message::before {
content: "⚠ ";
}