/* styles.css */

/* Default font size and margin left for xs and below */

.heading-1-fontsize {
    font-size: 60px;
    font-weight: 500;
  }
.heading-tm {
font-size: 30px;
  }
.home-pg-grid-padding {
    /* padding-left: 80px; */
    padding-top: 10px;
    padding-bottom: 10px;
} 
.gird-2-style {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.gird-2-padding-style {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 24px;
    padding-bottom: 24px;
}
.gird-2-ticker-style {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 24px;
    padding-bottom: 24px; 
}
.heading-5-fontsize{
    font-size: 38px;
    font-weight: 700;
    font-family: 'Satoshi';
    background-image: linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gird-8-style {
    background-color: #FFFFFF;
    padding-left: 150px;
    padding-right: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.gird-3-style {
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 1440px;
    margin: 0 auto;
    background-color: #FFFFFF;
}
.gird-5-style {
    padding-left: 120px;
    padding-top: 72px;
    padding-bottom: 72px;
}
.gird-6-style {
    padding-right: 120px;
    padding-top: 72px;
    padding-bottom: 72px;
}

.gird-9-style {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.gird-10-style {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.gird-11-style {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.gird-12-style {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: linear-gradient(to right, #004CFF, #06BCC1);
}

.gird-12-style-profile {
    padding-left: 90px;
    padding-top: 60px;
}
.gird-12-style-profile-1 {
    display: flex;
}
.gird-11-style-accordian {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 0px;
    padding-bottom: 30px; 
}

.line-break {
    height: 2px;
    background: linear-gradient(90deg, #004CFF 0%, #06BCC1 100%);
    opacity: 1;
    width: 615px;
    }  

.tryButton {
    width: 160px;
    height: 46px;
    text-transform: none;
    background: transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box;
    }
.box {
    border-radius: 20px;
    background: rgba(196, 196, 196, 0.16);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 60px;
    }   

.line-break-blog {
    height: 2px;
    background: linear-gradient(90deg, #004CFF 0%, #06BCC1 100%);
    opacity: 1;
    width: 100%
    }

.home-page-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: transparent linear-gradient(0deg, #06BCC1 0%, #004CFF 100%) 0% 0% no-repeat padding-box;
    background: linear-gradient(90deg, #004CFF 0%, #06BCC1 100%);
    opacity: 1;
}

.sponser-imageContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sponser-image {
  object-fit: contain;
  height: 100%;
  max-width: 22.33%; /* Each image will take up to one-third of the container's width */
  margin: 0;
  max-height: 90px;
  }

  .sponser-image1 {
    object-fit: contain;
    height: 100%;
    max-width: 38.33%; /* Each image will take up to one-third of the container's width */
    margin: 0;
    max-height: 90px;
    }

  .horizontal-scroll {
    display: flex;
    overflow: auto;
    /* Hide the scrollbar */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  /* For WebKit-based browsers like Chrome and Safari */
  .horizontal-scroll::-webkit-scrollbar {
    display: none;
  }
@media (max-width: 1400px) {
    .gird-5-style {
        padding-left: 60px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .gird-6-style {
        padding-right: 60px;
        padding-top: 30px;
        padding-bottom: 30px; 
    }
  }
  @media (max-width: 1200px) {
    .home-pg-grid-padding {
        padding-left: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
    } 
}
@media (max-width: 1024px) {
.gird-3-style {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.gird-6-style {
    padding-left: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.box {
    padding: 10px 10px;
    }   
}
@media (max-width: 960px) {
/* Tablets and smaller screens */
.gird-5-style {
    padding: 20px;
}
.gird-6-style {
    padding: 20px;
}
.gird-8-style {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.gird-9-style {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.gird-10-style {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.gird-11-style {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
}
.gird-11-style-accordian {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px; 
}
.gird-12-style {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
}
}
@media (max-width: 800px) {
    .heading-1-fontsize{
        font-size: 46px;
    }
    .heading-5-fontsize{
        font-size: 34px;
    }
    .gird-12-style-profile {
        padding-left: 0px;
        padding-top: 20px;
    }
    .gird-12-style-profile-1 {
        flex-direction: column;
        align-items: center;
    }
    .try-btn-placement{
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 715px) {
    .gird-2-style {
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 600px) {
    .gird-2-padding-style {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .heading-1-fontsize{
        padding: 10px;
        font-size: 36px;
    }
    .heading-tm {
        font-size: 15px;
    }
    .try-btn-placement{
        display: flex;
        justify-content: center;
    }
    .gird-8-style {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .sponser-image {
        max-width: 22.33%;
        max-height: 50px;
    }

      
        .sponser-image1 {
          max-width: 38.33%; /* Each image will take up to one-third of the container's width */
          max-height: 50px;
          }

    .gird-9-style {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .gird-3-style {
        padding-left: 2px;
        padding-right: 2px;
    }
    .gird-2-ticker-style {
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 24px;
        padding-bottom: 24px; 
    }
}
@media (max-width: 450px) {
    .gird-5-style {
        padding: 10px;
    }
    .heading-1-fontsize{
        font-size: 26px;
    }
    .heading-tm {
        font-size: 12px;
    }
    .heading-5-fontsize{
        font-size: 22px;
    }
    .gird-6-style {
        padding: 10px;
    }
}
@media (max-width: 300px) {
    .heading-1-fontsize{
        font-size: 20px;
    }
}
@media (min-width: 1025px) {
    .grid-2-style-ch1 {
        padding-left: 90px;
    }

    .grid-2-style-ch3 {
        padding-right: 90px;
    }
}
.clickable-item{
    width: '100%';
    justify-content: left;
}