/* ############ SECTION-1 */

#about_wrapper #banner-sec {
    background-position: 0 60% !important;
}

#banner-sec {
    height: 540px;
    background-position: center !important;
    display: flex;
    align-items: center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
}

.banner-sec-overlay {
    /* background-color: #0f58a5;
    background-image: url(wp-content/uploads/2019/09/overlay-bg.png);
    background-repeat: repeat;
    opacity: .7;
    transition: background .3s, border-radius .3s, opacity .3s;
    height: 100% !important;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0; */

    background: linear-gradient(90deg, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) 0% 0% no-repeat padding-box padding-box;
    /* background-image: url(/wp-content/uploads/2019/09/overlay-bg.png); */
    /* background-repeat: repeat; */
    opacity: .7;
    transition: background .3s, border-radius .3s, opacity .3s;
    height: 100% !important;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
}

.banner-sec-text {
    width: 90%;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1500px;
}

#banner-sec h1,
.banner-content h1 {
    font-size: 4rem;
    line-height: 1.2;
    font-weight: 700;
    color: #fff;
    margin-bottom: 1rem;
}

.text-white {
    color: #fff !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

/* ############ SECTION-2 */

.abt-spacing {
    padding: 70px 0;
}

#about_wrapper h2 {
    color: #0f58a5;
    font-size: 34px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
}

.abt-spacing h2 {
    color: #0f58a5;
    font-size: 34px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-bottom: 15px;
}

.text-center {
    text-align: center !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.gridContainerSpacing2 {
    width: 100%!important;
    padding-right: 15px!important;
    padding-left: 15px!important;
    margin-right: auto!important;
    margin-left: auto!important;
}

p.headerPara {
    color: #ffffff !important;
    font-size: 1.2rem !important;
    line-height: 1.6 !important;
    font-weight: 400 !important;
}

p.custom_para {
    color: #333 !important;
    font-size: 1.2rem !important;
    line-height: 1.6 !important;
    font-weight: 400 !important;
}

/* ############ SECTION-3 */

.started-3sec {
    box-shadow: 0 0 10px 0 #d6d6d6;
    border-radius: 8px;
    margin: 0 5.828px;
    padding: 3em 1em;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.started-3sec img {
    margin: 15px 0;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.btn-custom {
    font-weight: 600;
}

.btn-theme-custom {
    font-size: 16px;
    /* background: #ec5b2e; */
    background: transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box;
    /* border: 1px solid #ec5b2e; */
    padding: 10px 30px;
    display: inline-block;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    white-space: normal;
    position: relative;
    overflow: hidden;
}

.btn-custom {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

a.custom_anchor {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.imgCard {
    text-align: center;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
}

/* ############ SECTION-4 */

#get-started {
    position: relative;
}

.get-started-overlay {
    padding: 50px 0;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

#get-started:before {
    content: "";
    position: absolute;
    width: 100%;
    background: linear-gradient(90deg, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) 0% 0% no-repeat padding-box padding-box;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .9;
}

#about_wrapper .getstared-left-sec h2 {
    font-size: 42px;
    color: #ffffff;
}

#about_wrapper h2 {
    color: #0f58a5;
    font-size: 34px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
}

.bt_custom_cls {
    position: relative;
    width: 100%;
    min-height: 1px;
    /* padding-right: 15px;
    padding-left: 15px */
}

.gird-aboutus-style {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 60px;
    padding-bottom: 0px;
}

@media (max-width: 960px) {
    /* Tablets and smaller screens */
    .gird-aboutus-style {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 0px;
    }
}

@media (max-width: 600px) {
    .gird-aboutus-style {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 0px;
    }
}