@media (min-width: 0px) and (max-width: 768px) {
    button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      font-weight: 700 !important;
    }
  
    button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      color: #08070D !important;
      text-transform: none;
      font-weight: 400 !important;
    }
  
    span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
      background-color: #004CFF !important;
    }
  }