.logo {
    padding-left: 20px;
    width: 200px
}


.link-button {
    letter-spacing: 0px;
    opacity: 1;
    color: #08070D;
    text-transform: initial;
    text-decoration: none;
    display: inline-block;
}

.link-signin {
    letter-spacing: 0px;
    opacity: 1;
    color: #08070D;
    text-transform: initial;
    text-decoration: none;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
}

.dashboart-text {
    color: #08070D;
    text-align: left;
    font-size: 18px;
    line-height: 70px;
    letter-spacing: 0px;
    padding-left: 5;
    opacity: 1;
    font-family: 'Satoshi';
}

.header-line {
position: absolute;
left: 0;
width: 100%;
height: 2px;
background: transparent linear-gradient(0deg, #06BCC1 0%, #004CFF 100%) 0% 0% no-repeat padding-box;
background: linear-gradient(90deg, #004CFF 0%, #06BCC1 100%);
opacity: 1;
}

.headder-line-desktop {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: #E4EBFC;
    opacity: 1;
}

.showOnTablet {
    display: none;
}

@media (max-width: 959px) {
    .showOnTablet {
        display: block;
    }
}
@media (max-width: 900px) {
    .logo {
        width: 140px;
        padding-left: 0px;
    }
}
@media (max-width: 625px) {
    .logo {
        padding-left: 5px;
        width: 120px;
    }
}

@media (max-width: 600px) {
    .link-button{
        font-family: 'Satoshi';
        font-weight: 700;
        font-size: 14px;
        color: #08070D;
    }
.header {
    height: 56px;
}

.logo {
    padding-left: 5px;
    width: 160px;
}

.dashboart-text {
    font-size: 12px;
    padding-left: 5px;
    line-height: 56px;
    font-family: 'Satoshi';
    font-weight: bold;
}

.button {
    font-size: 12px;
    margin-left: 20px;
    padding-left: 20px;
}
.link-signin {
    padding-left: 0px;
    padding-right: 0px;
}
}