.pricing-style {
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.pricing-box {
    border-radius: 20px;
    background: rgba(196, 196, 196, 0.16);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
} 
.pricing-line-break {
    height: 2px;
    background: linear-gradient(90deg, #004CFF 0%, #06BCC1 100%);
    opacity: 1;
    width: 100%
}
.gird-pricing-style {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 60px;
    padding-bottom: 0px;
}
.pricing-line-break-1 {
    height: 1px;
    background: #D4D4D4;
    opacity: 1;
    width: 100%
}
@media (max-width: 1024px) {
    .pricing-box {
        padding: 10px 10px;
        }   
    }
@media (max-width: 960px) {
    /* Tablets and smaller screens */
    .pricing-style {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .gird-pricing-style {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 0px;
    }
}

@media (max-width: 600px) {
    .pricing-style {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .gird-pricing-style {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 0px;
    }
}