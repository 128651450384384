.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ag-header.ag-pivot-off {
  height: 70px !important;
  background-color: #EFF3FC !important;
}

.ag-theme-alpine .ag-row:nth-child(odd) {
  background-color: #F7F9FD !important;
}

.ag-theme-alpine .ag-cell {
  border-right: 1px solid #EFF3FC !important;
  font-family: 'Satoshi';
  font-size: 15px;
}

.ag-theme-alpine .ag-header {
  font-family: 'Satoshi';
}

.ag-theme-alpine .ag-pinned-left-header,
.ag-theme-alpine .ag-pinned-right-header {
  /* border-right: 1px solid #EFF3FC; */
  border-right: 1px solid #EFF3FC !important;
}

.ag-theme-alpine .ag-pinned-left-cols-container .ag-cell,
.ag-theme-alpine .ag-pinned-right-cols-container .ag-cell {
  border-right: 1px solid #EFF3FC !important;
}

.upperCase {
  text-transform: uppercase;
}

.symbolCss {
  top: 10px;
  position: relative;
  left: -38px;
  font-weight: 400;
}

.platformCss {
  top: 2px;
  position: relative;
  left: 50%;
  background-color: #DDE0E8;
  border-radius: 3px;
  padding: 3px 6px;
}

.gridFirstColumnCss {
  position: relative;
  top: -8px;
}

.example-header {
  float: right;
  color: #08070D;
  padding: 2px;
}

span.ag-paging-row-summary-panel {
  display: none;
}

@media only screen and (max-width: 959px) {
  .platformCss {
    left: 10px;
  }

}

.pdLeft {
  padding-left: 5px;
}

.ag-cell,
.ag-header.ag-pivot-off,
.ag-root-wrapper {
  border: 1px solid #E3EBFD !important;
}


/* @font-face {
  font-family: 'Satoshi';
  src: url('../public/fonts/Satoshi-Regular.ttf')format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Satoshi';
  src: url('../public/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../public/fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../public/fonts/Satoshi-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../public/fonts/Satoshi-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #004CFF;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.MuiPaginationItem-root.Mui-selected {
  background-color: blue!important;
  color: white;
}

.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.homepg-logo {
  width: 200px
}

@media (max-width: 715px) {
  .homepg-logo {
    width: 140px
  }
}

@media (max-width: 450px) {
  .homepg-logo {
    width: 120px
  }
}