.footer {
    background: linear-gradient(to right, #004CFF, #06BCC1);
    opacity: 1;
    flex-shrink: 0;
}
.footer-logo {
    padding-left: 20px;
    width: 110px
}
.footerContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.footer-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: transparent linear-gradient(0deg, #06BCC1 0%, #004CFF 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    }

.footer-line-desktop {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: #E4EBFC;
    opacity: 1;
}

.copyright-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: #E3EBFD;
    opacity: 1;
    }

.footerDiv {
    display: flex;
    align-items: center;
    height: 34px;
}

.copyrightText {
    font-size: 34px;
    color: #08070D;
    opacity: 1;
    padding-left: 20px;
}

.linkContent {
    background-color: rgb(158, 194, 194);
    right: 0px;
}

.link-footer {
    letter-spacing: 0px;
    opacity: 1;
    font-weight: 300;
    color: #08070D;
    text-transform: initial;
    text-decoration: none;
    display: inline-block;
}

.footer-link {
    padding-right: 20px;
}

@media (max-width: 600px) {
    /* Tablets and smaller screens */
    .footerContent{
        justify-content: center;
      }
    .copyrightText{
        padding-left: 0px;
    }
}

